<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="dataSource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            v-on:detailinit="detailInit"
            :resizable="true"
            >
        </kendo-grid>

        <file-viewer ref="fileViewer"/>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import {globalfunc} from '../../../../shared/GlobalFunction';
import requestServices from '../Script/RequestServices';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import { responsePathAsArray } from 'graphql';
import FileViewer from '../../../../shared/FileViewer.vue';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'PurshasingPurchaseOrderGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'file-viewer': FileViewer,
    },
    props: ['editClick', 'deleteClick', 'statusClick', 'pdfClick'],
    data: function () {
        return  {
            dataSource:  new kendo.data.DataSource ({
                page: 1,
                pageSize: 50,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            const variables = {
                                paging : paging
                            }
                            return { query: requestServices.readRequestQuery(),
                                        variables : variables}
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetPurchasingRequestV.purchasing_request == null){
                            return [];
                        }else{
                            return response.data.GetPurchasingRequestV.purchasing_request;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetPurchasingRequestV.purchasing_request == null){
                            return 0;
                        }else{
                            return response.data.GetPurchasingRequestV.total;
                        }
                    },
                    model: {
                        fields: {
                            request_date: { type: "date" },
                            created_at: { type: "date" },
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" },
            }),
            columns:  [
                { title: "Action", width: 200, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "status", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} }, 
                { field: "request_number", title: "No. Request", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "request_date", title: "Tanggal Request", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}, 
                    template: "#= kendo.toString(kendo.parseDate(request_date, 'yyyy-MM-dd'), 'yyyy-MM-dd') #"  },
                { field: "created_at", title: "Tanggal Dibuat", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"},  
                    template: "#= kendo.toString(kendo.parseDate(new Date(created_at).toISOString(), 'yyyy-MM-dd'), 'yyyy-MM-dd') #"  },
                { field: "address", title: "Alamat", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "notes", title: "Catatan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "attachment", title: "Attachment", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    template:this.columnFile },
                { field: "created_by", title: "Dibuat Oleh", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },    
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        };
    },
    mounted: async function() {
        var vueComponent = this;
        var grid = this.$refs.grid.kendoWidget();
        var deleteClick = this.$props.deleteClick;
        var editClick = this.$props.editClick;
        var statusClick = this.$props.statusClick;
        var pdfClick = this.$props.pdfClick;
       
        var GridElement = grid.element;

        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            deleteClick(dataItem.request_id);
        })

        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.request_id, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.request_id, true);
        })
        
        ////Status Form
        GridElement.on("click", "#StatusButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            const reqData = {
                request_id : dataItem.request_id,
                request_number : dataItem.request_number,
                status : dataItem.status
            }
            
            statusClick(reqData);
        })

        // GridElement.on("click", "#PdfButton", function (e){
        //     e.preventDefault();
        //     var dataItem = grid.dataItem(e.target.closest("tr"));

        //     pdfClick(dataItem.po_id)
        // })

        //File Form
        GridElement.on("click", "#FileButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            vueComponent.$refs.fileViewer.viewClick(dataItem.attachment);
        })
      
    },
    methods: {
        columnButton(){
            // var customButton = `&nbsp;&nbsp;
            //                     <button type="button" class="btn btn-secondary btn-sm rounded" id="PdfButton">
            //                         <i class="fa fa-file-pdf-o"></i> </span>
            //                     </button>`;
            // return this.$globalfunc.gridActionWithStatusChangesButton("Request", customButton)
            return this.$globalfunc.gridActionWithStatusChangesButton("Request")
        },
        columnFile(e){
            return this.$globalfunc.gridFileViewButton(e.attachment);
        },
        detailInit: async function (e) {
            var data = await requestServices.getRequestData(e.data.request_id);
            // var html = await purchasingPOServices.PurchasingPODetailTemplate(data);

            // $('<div/>').appendTo(e.detailCell).html(html);
            // $('<div/>').appendTo(e.detailCell).html('<label class="form-label"><b>Daftar Barang</b></label>');
            $('<div/>').appendTo(e.detailCell).kendoGrid({
                ref: "detailGrid",
                dataSource: {
                    transport: {
                        read: function (x) {
                            x.success(data.purchasing_request_detail);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify(
                            {
                                query: options.query,
                                variables: options.variables
                            }
                        );
                    },
                    schema: {
                        data: function (response) {
                            if(response == null)
                                return []
                            else
                                return response
                        },
                        total: function(response) {
                            if(response == null)
                                return 0
                            else
                                return response.length;
                        }
                    },
                    pageSize: kendo_grid.default_detail_grid_pagesize,
                },
                scrollable: false,
                sortable: true,
                pageable: true,
                navigatable: true,
                resizable: true,
                columns: [
                    { field: 'product_code', title: "Kode Barang", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                    { field: 'item_name', title: "Nama Barang", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },  editable: false, nullable: true },
                    { field: 'quantity', title: "Jumlah", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true, attributes: { "class": "k-text-right" } },
                    { field: 'uom', title: "Satuan", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                ],
            });
        },
        changeStatus(status){
            this.dataSource =  new kendo.data.DataSource ({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            const variables = {
                                paging : paging,
                                status : status
                            }
                            return { query: requestServices.readRequestQuery(),
                                        variables : variables}
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetPurchasingRequestV.purchasing_request == null){
                            return [];
                        }else{
                            return response.data.GetPurchasingRequestV.purchasing_request;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetPurchasingRequestV.purchasing_request == null){
                            return 0;
                        }else{
                            return response.data.GetPurchasingRequestV.total;
                        }
                    },
                    model: {
                        fields: {
                            request_date: { type: "date" },
                            created_at: { type: "date" },
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" },
            })
        },
    }
}
</script>