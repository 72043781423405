<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Order</h2>
            </CCardHeader>
            <CCardBody>
                <span v-html="statusButton"></span>

                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"                   
                    @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>&nbsp;
                </div>

                <request-order-create-form ref="requestOrderCreateForm"/>
                <request-order-grid ref="grid" :key="gridReload" :editClick="editClick" :deleteClick="deleteClick" :statusClick="statusClick" :pdfClick="pdfClick"/>
                <request-order-status-form ref="RequestOrderStatusForm" :reload="reload"/>
                <request-order-pdf ref="pdf" />
                <request-order-pdf-english ref="pdfEnglish" />
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { globalfunc } from '../../../shared/GlobalFunction';
import requestOrderCreateForm from './Component/RequestOrderCreateForm.vue';
import requestOrderGrid from '../RequestOrder/Grid/RequestOrderGrid.vue';
import requestOrderStatusForm from '../RequestOrder/Component/RequestOrderStatusForm.vue';
import requestOrderServices from '../RequestOrder/Script/RequestOrderServices.js';
import requestOrderPDF from '../../../assets/template/RequestOrderPdfTemplate.vue';
import requestOrderPDFEnglish from '../../../assets/template/RequestOrderPdfTemplateEnglish.vue';
import { response } from '../../../infrastructure/constant/response';
import { sweet_alert } from '../../../infrastructure/constant/variable';

export default {
    name: 'PermintaanOrder',
    components: {
        'request-order-grid': requestOrderGrid,
        'request-order-create-form': requestOrderCreateForm,
        'request-order-status-form': requestOrderStatusForm,
        'request-order-pdf': requestOrderPDF,
        'request-order-pdf-english': requestOrderPDFEnglish,
    },
    async mounted () {
        var permission = this.$globalfunc.permissionModule('Order');
        document.getElementById("add").disabled = permission.cperms ? false : true;
        
        this.statusFilter();
    },
    data(){
        return{
            gridReload: 0,
            statusButton: '',
        }
    },
    methods: {
        addClick(){
            this.$refs.requestOrderCreateForm.addClick();
        },
        editClick(id, view){
            // this.$router.push({ name: 'Order Form', params: { formtype:'Edit', id:id, view:view } });
            const routeData = this.$router.resolve({ name: 'Order Form', params: { formtype:'Edit', id:id, view:view } });
            window.open(routeData.href, '_blank');
        },
        async deleteClick(id){
            var data = await requestOrderServices.getPOQuery(id);
            var itemGridRequestOrder = [];

            if(data.purchasing_purchase_order_detail.length != 0 ){
                for (let i = 0; i < data.purchasing_purchase_order_detail.length; i++) {
                    var str = 
                    { 
                        item_id: data.purchasing_purchase_order_detail[i].item_id,
                        uom: data.purchasing_purchase_order_detail[i].uom,
                        discount: data.purchasing_purchase_order_detail[i].discount,
                        quantity: data.purchasing_purchase_order_detail[i].quantity,
                        price_per_unit: data.purchasing_purchase_order_detail[i].price_per_unit,
                        price: data.purchasing_purchase_order_detail[i].price,
                        vat_per_unit: data.purchasing_purchase_order_detail[i].vat_per_unit,
                        vat: data.purchasing_purchase_order_detail[i].vat,
                    } 
                    itemGridRequestOrder.push(str);
                }
            }

            const poData = {
                purchase_order_number: data.purchase_order_number,
                status: 'Delete',
                request_id: data.request_id,
                terms_of_payment: data.terms_of_payment,
                purchase_order_date: data.purchase_order_date,
                ppn_tax_percentage: data.ppn_tax_percentage,
                supplier_id: data.supplier_id,
                currency_id: data.currency_id,
                exchange_rate: data.exchange_rate,
                notes: data.notes,
                printed_notes: data.printed_notes,
                address: data.address,
                attachment: null,
                down_payment: data.down_payment,
                purchasing_purchase_order_detail:itemGridRequestOrder
            }

            const variables = {
                id : parseInt(id),
                data : poData
            }

            this.$swal(sweet_alert.delete).then((result) => {
                if (result.isConfirmed == true) {
                    requestOrderServices.editQuery(variables).then(res => {
                        this.reload();
                        this.$swal("Info", response.successDelete, "success");                 
                    }).catch(error => {
                        var errorMessage = this.$globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
        statusClick(data) {
            this.$refs.RequestOrderStatusForm.statusClick(data);
        },
        reload(){
            this.gridReload++;
            this.statusFilter();
        },
        async statusFilter() {
            // var countStatus = await globalfunc.countTotal(window.location.pathname);
            // this.statusButton = await this.$globalfunc.dynamicStatusButton('ddl_status_purchasing_purchase_order', countStatus);
            this.statusButton = await this.$globalfunc.dynamicStatusButton();
            
            var vue = this;
            this.$nextTick(function () {
                $("#btnAll").on('click', function() {
                    vue.reload();
                });
                $("#btnRevisi").on('click', function() {
                    vue.$refs.grid.changeStatus('Revisi');
                });
                $("#btnChecking").on('click', function() {
                    vue.$refs.grid.changeStatus('Checking');
                });
                $("#btnApproved").on('click', function() {
                    vue.$refs.grid.changeStatus('Approved');
                });
                $("#btnDelete").on('click', function() {
                    vue.$refs.grid.changeStatus('Delete');
                });
                $("#btnFinish").on('click', function() {
                    vue.$refs.grid.changeStatus('Finish');
                });
                $("#btnNew").on('click', function() {
                    vue.$refs.grid.changeStatus('New');
                });
                $("#btnReady").on('click', function() {
                    vue.$refs.grid.changeStatus('Ready');
                });
            })
        },
        pdfClick(id, type) {
            // this.$swal(sweet_alert.print).then((result) => {
            //     if (result.isConfirmed == true) {
            //         if(type == 'ID'){
            //             this.$refs.pdf.generatePDF(id);
            //         }else{
            //             this.$refs.pdfEnglish.generatePDF(id);
            //         }
            //     }else if (result.isDenied) {
            if(type == 'ID'){
                const routeData = this.$router.resolve({name: 'Request Order Html Template', params: { id:id }});
                window.open(routeData.href, '_blank');
            }else{
                const routeData = this.$router.resolve({name: 'Request Order English Html Template', params: { id:id }});
                window.open(routeData.href, '_blank');
            }
            //     }
            // });
            
        },
    }
}
</script>

<style scoped>
</style>