<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>{{FormType}} Order : {{PONumber}}</h2>
            </CCardHeader>
            <CCardBody>
                <div class="px-3">
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Nomor PO</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="PONumber" v-model="PONumber" class="font-weight-bold"/>
                            <label id="errorPONumber" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Nomor Request</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="RequestNumber" v-model="RequestNumber" class="font-weight-bold" readonly/>
                            <label id="errorRequestNumber" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Tanggal</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <date-picker
                                v-model="PODate"
                                format="DD MMM YYYY"
                                type="date"
                                placeholder="Select date"
                                value-type="YYYY-MM-DD"
                                style="width:100%"
                            ></date-picker>
                            <label id="errorPODate" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Status</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                            <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Pajak PPN (%)</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CSelect id="PPNTax" class="font-weight-bold" :options="PPNTaxData" :value.sync="PPNTax" @change="onChangeItemGridData()"/>
                            <label id="errorPPNTax" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Vendor</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="Vendor" class="pb-3" :options="VendorData" v-model="Vendor"/>
                            <label id="errorVendor" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Mata Uang</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CSelect id="Currency" class="font-weight-bold" :options="CurrencyData" :value.sync="Currency" @change="onChangeCurrency()"/>
                            <label id="errorCurrency" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Kurs</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput type="number" id="ExchangeRate" v-model="ExchangeRate" class="font-weight-bold" @change="onChangeSaldo()"/>
                            <label id="errorExchangeRate" class="form-error" style="display: none; color: red;"></label>
                        </div>
                        
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Attachment</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                             <div class="row">
                                <div class="col-8 p-0 pl-3">
                                    <div class="form-control">
                                        <div v-if="this.FileStatus == 'Uploaded'">
                                            <a href="#" @click="fileClick()" class="label-file" id="uploadedFile"><label>{{File}}</label></a>
                                        </div>
                                        <div v-if="this.FileStatus == 'New'">
                                            <label class="label-file">{{File}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2 p-0 px-2">
                                    <label for="File" class="form-control" style="text-align: center;"><i class="fa fa-upload"></i></label>
                                    <input type="file" id="File" @input="onChangeFile()" style="display:none"/>
                                </div>
                                <div class="col-2 p-0 pr-3">
                                    <CButton type="button" @click="deleteAttachmentClick()" id="deleteAttachmentButton" color="danger" style="width:100%"> <i class="fa fa-trash"></i> </CButton>
                                </div>
                            </div>
                            <label id="errorFile" class="form-error" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Diskon Plus (%)</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput type="number" id="DiscPlus" v-model="DiscPlus" class="font-weight-bold" @change="onChangeItemGridData()"/>
                            <label id="errorDiscPlus" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Termin</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput type="number" id="TermsOfPayment" v-model="TermsOfPayment" class="font-weight-bold"/>
                            <label id="errorTermsOfPayment" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Alamat</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <textarea class="form-control" rows="2" v-model="Address"></textarea>
                            <label id="errorAddress" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Note</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <textarea class="form-control" rows="2" v-model="Note"></textarea>
                            <label id="errorNote" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Note Print</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <textarea class="form-control" rows="2" v-model="NotePrint"></textarea>
                            <label id="errorNotePrint" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <br>
                    <CRow class="p-3">
                        <div class="p-3 border rounded" style="width: 100%;">
                            <label class="form-label font-weight-bold" style="margin-bottom: 0px"><h4>Item</h4></label>
                            <hr>
                            <datasource ref="itemDataSource" :data="this.ItemGridData" :page-size="10" :schema-model-fields="this.ItemSchemaModel" 
                            :aggregate="[
                                    { field: 'vat', aggregate: 'sum' }
                                ]"
                            />

                            <kendo-grid ref="gridItem"
                                        :data-source-ref="'itemDataSource'"
                                        :editable="true"
                                        :filterable="true"
                                        :sortable="true"
                                        :pageable="true"
                                        :cellClose="itemCellClose"
                                        :resizable="true"
                                        >

                                <kendo-grid-column  :field="'product_code'"
                                                    :title="'Kode'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                 <kendo-grid-column  :field="'item_name'"
                                                    :title="'Nama'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'uom'"
                                                    :title="'Satuan'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                               
                                <kendo-grid-column  :field="'request_quantity'"
                                                    :title="'Request Quantity'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"></kendo-grid-column>

                                <kendo-grid-column  :field="'ordered_quantity'"
                                                    :title="'Ordered Quantity'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"></kendo-grid-column>

                                <kendo-grid-column  :field="'remaining_quantity'"
                                                    :title="'Remaining Quantity'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"></kendo-grid-column>

                                <kendo-grid-column  :field="'quantity'"
                                                    :title="'Quantity'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"></kendo-grid-column>

                                <kendo-grid-column  :field="'price_per_unit'"
                                                    :title="'Harga Dasar Item'"
                                                    :format="'{0:N2}'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"></kendo-grid-column>

                                <kendo-grid-column  :field="'vat_per_unit'"
                                                    :title="'Harga'"
                                                    :format="'{0:N2}'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"></kendo-grid-column>

                                <kendo-grid-column  :field="'vat'"
                                                    :title="'Total'"
                                                    :format="'{0:N2}'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"
                                                    :aggregates="['sum']"
                                                    :footerTemplate="`<div style='float: right'>` + currSymbol + ` #= kendo.toString(Math.round(sum), 'n2') #</div>`"></kendo-grid-column>
                            </kendo-grid>
                        </div>
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">DP</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="DP" v-model="DP" class="font-weight-bold form-control input-number"/>
                            <label id="errorDP" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <br>
                    <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                    <label style="float: right;">&nbsp;&nbsp;</label>
                    <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close" @click="$router.push('/order')"> Close </CButton>
                </div>
            </CCardBody>
        </CCard>
        <file-viewer ref="fileViewer"/>
    </div>
</template>

<script>
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import requestOrderServices from '../Script/RequestOrderServices';
import currencyService from '../../../MasterData/Currency/Script/CurrencyService.js';
import FileViewer from '../../../../shared/FileViewer.vue';
import moment from 'moment';
import { response } from '../../../../infrastructure/constant/response';
import { baseUrl } from '../../../../infrastructure/constant/connection';

export default {
    name: 'RequestOrderFormPage',
    components: {
        'date-picker' : datePicker,
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
        'file-viewer': FileViewer,
    },
    async mounted () {
        if(this.FormType == 'Add'){
            var data = await requestOrderServices.getDetailRequest(this.POId);

            this.POId= this.$route.params.id,
            this.PONumber= 'Auto';
            this.RequestId= data.request_id;
            this.RequestNumber= data.request_number;
            var dateNow = moment(new Date()).format("YYYY-MM-DD");
            this.PODate= dateNow;
            this.StatusData = [ {value: 'New', label: 'New'} ];
            this.Status = 'New';
            this.PPNTaxData = await requestOrderServices.getDropdownPPNTax();
            var ppnTax = await globalfunc.getPPNTax();
            this.PPNTax = ppnTax.label;
            this.VendorData = await requestOrderServices.getVendorQuery();
            this.Vendor = '';
            this.CurrencyData = await requestOrderServices.getCurrency();
            this.Currency = this.CurrencyData.find(c => c.label == 'IDR').value;
            this.ExchangeRate = 1;
            this.DiscPlus = 0;
            this.TermsOfPayment = 0;

            //File
            this.File = '';
            this.FileStatus = 'New';

            this.Address = data.address;
            this.Note = '';
            this.NotePrint = '';

            //Grid
            this.ItemGridData = await requestOrderServices.getPOAbleItemList(this.POId);
            this.onChangeItemGridData();

            this.DP= 0,
            this.SaveType = 'Add';
            
            var currData = await currencyService.getCurrencyById(this.Currency);
            this.currSymbol = currData.currency_symbol;
            this.onChangeSaldo();
        }
        else {
            var data = await requestOrderServices.getPOQuery(this.POId);
            var dataRequest = await requestOrderServices.getDetailRequest(data.request_id);
            
            this.POId= this.POId,
            this.PONumber= data.purchase_order_number;
            this.RequestId= data.request_id;
            this.RequestNumber= data.request_number;
            this.PODate= data.purchase_order_date;
            this.StatusData = await this.$globalfunc.globalDropdown('ddl_status_purchasing_purchase_order');
            this.Status = data.status;
            this.PPNTaxData = await requestOrderServices.getDropdownPPNTax();
            this.PPNTax = data.ppn_tax_percentage;
            this.VendorData = await requestOrderServices.getVendorQuery();
            this.Vendor = this.VendorData.find(c => c.value == data.supplier_id);
            this.CurrencyData = await requestOrderServices.getCurrency();
            this.Currency = data.currency_id;
            this.ExchangeRate = data.exchange_rate;
            this.DiscPlus = data.purchasing_purchase_order_detail[0].discount;
            this.TermsOfPayment = data.terms_of_payment;

            //File
            this.File = data.attachment;
            this.FileStatus = 'Uploaded';

            this.Address = data.address;
            this.Note = data.notes;
            this.NotePrint = data.printed_notes;

            //Grid
            this.ItemGridData = data.purchasing_purchase_order_detail;

            this.DP= data.down_payment,
            this.SaveType = 'Edit';

            var currData = await currencyService.getCurrencyById(this.Currency);
            this.currSymbol = currData.currency_symbol;

            document.getElementById('PONumber').readOnly = true;

            if(this.View == 'true'){
                $(".save-button").hide();
            }
        }
    },
    data(){
        return{
            FormType: this.$route.params.formtype,
            View: this.$route.params.view,
            Error : 0,

            //Detail
            POId: this.$route.params.id,
            PONumber: '',
            RequestId: '',
            RequestNumber: '',
            PODate: '',
            Status: '',
            StatusData: [],
            PPNTax: '',
            PPNTaxData: [],
            Vendor: '',
            VendorData: [],
            Currency: '',
            CurrencyData: [],
            ExchangeRate: 0,
            DiscPlus: 0,
            TermsOfPayment: 0,

            File: '',
            FileStatus: '',

            Address: '',
            Note: '',
            NotePrint: '',

            DP: 0,

            currSymbol: '',

            SaveType: '',

            //grid
            ItemGridData: [],
            ItemSchemaModel: {
                product_code: { type: "string", editable: false },
                item_name: { type: "string", editable: false },
                uom: { type: "string", editable: false },
                request_quantity: { type: "number", editable: false },
                ordered_quantity: { type: "number", editable: false },
                remaining_quantity: { type: "number", editable: false },
                quantity: { type: "number", editable: true, validation: { min:0 } },
                price_per_unit: { type: "number", editable: true, validation: { min:0 } },
                total: { type: "number", editable: false },
                vat_per_unit: { type: "number", editable: false },
                vat: { type: "number", editable: false },
            },
        }
    },
    methods: {
        async onChangeCurrency() {
            var currData = await currencyService.getCurrencyById(this.Currency);
            this.currSymbol = currData.currency_symbol;
        },
        async onChangeSaldo(){            
            var CurrencyData = await requestOrderServices.getCurrency();
            var Currency = CurrencyData.find(c => c.value == this.Currency).label;

            if(Currency == ""){
                Currency = "IDR";
            }

            if(Currency == "IDR"){
                this.ExchangeRate = 1;
            }
            
            if(Currency != "IDR" && this.ExchangeRate <= 0){
                this.$swal("Error", "Kurs tidak boleh lebih kecil dari 0", "error");
                this.ExchangeRate = 1;
            }
        },
        onChangeItemGridData(){
            this.DiscPlus = this.$globalfunc.percentValidate(this.DiscPlus);
            var newItemGridData = requestOrderServices.itemDataChange(this.ItemGridData, this.DiscPlus, (this.PPNTax/100));
            this.ItemGridData = newItemGridData;
        },
        itemCellClose(e){
            var dataItem = e.sender.dataItem($(e.container).parent());
            if(dataItem.quantity == null){
                dataItem.quantity = 0;
            }
            if(dataItem.quantity > dataItem.remaining_quantity){
                this.$swal("Error", "Quantity tidak boleh lebih dari remaining quantity", "error");
                dataItem.quantity = dataItem.remaining_quantity;
            }
            var gridData = this.$refs.gridItem.kendoWidget().dataSource._data;

            var newItemGridData = requestOrderServices.itemDataChange(gridData, this.DiscPlus, (this.PPNTax/100));
            this.ItemGridData = newItemGridData;
        },
        onChangeFile(){
            var file = document.getElementById('File').files[0];
            this.FileStatus = 'New';
            this.File = file.name;
        },
        fileClick(){
            this.$refs.fileViewer.viewClick(this.File);
        },
        inputValidation(){
            this.Error = 0;
            $(".form-error").css("display", "none");

            //Validation
            if(this.PONumber == '' || this.PONumber == null){
                this.errorShow('errorPONumber');
            }
            if(this.PODate == '' || this.PODate == null){
                this.errorShow('errorPODate');
            }
            if(this.Vendor == '' || this.Vendor == null){
                this.errorShow('errorVendor');
            }
            if(this.Address == ''){
                this.errorShow('errorAddress');
            }

            if(this.TermsOfPayment.toString() == '' || this.TermsOfPayment == null){
                this.errorShow('errorTermsOfPayment');
            }
            else if (this.TermsOfPayment < 0) {
                this.errorShow('errorTermsOfPayment', response.fieldRequiredNotMinus);
            }

            if (this.ExchangeRate.toString() == '' || this.ExchangeRate == null){
                this.errorShow ('errorExchangeRate')
            }
            else if (this.ExchangeRate < 0) {
                this.errorShow('errorExchangeRate', response.fieldRequiredNotMinus);
            }

            if (this.DiscPlus.toString() == '' || this.DiscPlus == null){
                this.errorShow ('errorDiscPlus')
            }
            else if (this.DiscPlus < 0) {
                this.errorShow('errorDiscPlus', response.fieldRequiredNotMinus);
            }

            if (this.DP.toString() == '' || this.DP == null){
                this.errorShow ('errorDP')
            }
            else if (this.DP < 0) {
                this.errorShow('errorDP', response.fieldRequiredNotMinus);
            }

            if(this.ItemGridData.length < 1){
                this.$swal("Error", "Item tidak boleh kosong", "error");
            }
        },
        errorShow(errorCode, message){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = message == undefined ? response.fieldRequired : message;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
        async saveClick(){
            this.inputValidation();

            var file = document.getElementById('File').files[0];
            
            if(file == undefined)(
                file = null
            )

            //Proses
            if(this.Error == 0){
                //set grid
                var data = this.$refs.gridItem.kendoWidget().dataSource._data;
                var itemGridRequestOrder = [];

                if(data.length != 0 ){
                    for (let i = 0; i < data.length; i++) {
                        var str = { 
                            item_id: data[i].item_id,
                            uom: data[i].uom,
                            discount: parseFloat(this.DiscPlus),
                            quantity: data[i].quantity,
                            price_per_unit: data[i].price_per_unit,
                            price: data[i].price,
                            vat_per_unit: data[i].vat_per_unit,
                            vat: data[i].vat,
                        } 
                        itemGridRequestOrder.push(str);
                    }
                }
                
                if (this.SaveType == 'Add'){
                    this.$loading(true);

                    const poData = {
                        purchase_order_number: this.PONumber,
                        status: this.Status,
                        request_id: this.RequestId,
                        terms_of_payment: this.TermsOfPayment,
                        purchase_order_date: this.PODate,
                        ppn_tax_percentage: this.PPNTax,
                        supplier_id: this.Vendor.value,
                        currency_id: this.Currency,
                        exchange_rate: this.ExchangeRate,
                        notes: this.Note,
                        printed_notes: this.NotePrint,
                        address: this.Address,
                        attachment: file,
                        down_payment: parseFloat(this.DP),
                        purchasing_purchase_order_detail:itemGridRequestOrder
                    }
                    
                    const variables = {
                        data : poData
                    }
                    
                    requestOrderServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success").then(
                            this.$router.push({ path: '/order' })
                        );
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }else if (this.SaveType == 'Edit'){
                    this.$loading(true);

                    const poData = {
                        purchase_order_number: this.PONumber,
                        status: this.Status,
                        request_id: this.RequestId,
                        terms_of_payment: this.TermsOfPayment,
                        purchase_order_date: this.PODate,
                        ppn_tax_percentage: this.PPNTax,
                        supplier_id: this.Vendor.value,
                        currency_id: this.Currency,
                        exchange_rate: this.ExchangeRate,
                        notes: this.Note,
                        printed_notes: this.NotePrint,
                        address: this.Address,
                        attachment: file,
                        down_payment: parseFloat(this.DP),
                        purchasing_purchase_order_detail:itemGridRequestOrder
                    }

                    const variables = {
                        id : parseInt(this.POId),
                        data : poData
                    }

                    requestOrderServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success")
                            .then(res => {
                                if(res.isConfirmed == true || res.isDismissed == true){
                                    window.location = baseUrl + '/order'
                                }
                            }); 
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        deleteAttachmentClick(){
            $('#File').val('');
            this.FileStatus = '';
            this.File = '';
        }
    }
}
</script>

<style scoped>
.col-sm-label, .col-sm-content, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
@media (min-width: 768px) {
    .col-sm-label, .col-sm-content, .col-sm-half {
        float: left;
    }
    .col-sm-label {
        width: 12%;
    }
    .col-sm-content {
        width: 21%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}
>>> {
    --vs-controls-size: 0.7;
    --vs-actions-padding: 4px 2px 0 3px;
    --vs-border-color: #d8dbe0;
    --vs-selected-color: #768192;
    --vs-dropdown-option-color: #768192;
    --vs-selected-max-height: 10px;
}
.label-file {
  width : 100%;
  overflow:hidden;
  display:inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>