<template>
    <div>
        <input hidden type="text" class="form-control" id="RequestOrderId" v-model="RequestOrderId">
        <div class="modal fade" id="RequestOrderStatusModal" tabindex="-1" aria-labelledby="RequestOrderStatusModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="RequestOrderStatusModalLabel" class="font-weight-bold">{{ModalTitle}}{{RequestOrderNo}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <div class="p-3">
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Status</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                                    <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                        </div>
                       
                        <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import {globalfunc} from '../../../../shared/GlobalFunction';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import requestOrderServices from '../Script/RequestOrderServices.js';
import { response } from '../../../../infrastructure/constant/response';
import { sweet_alert } from '../../../../infrastructure/constant/variable';

export default {
    name: 'RequestOrderStatusForm',
    components: {
    },
    props: ['reload'],
    data: function () {
        return {
            ModalTitle : '',

            RequestOrderId : '',
            RequestOrderNo : '',
            Status : '',
            StatusData : [],
        }
    },
    methods: {
        async statusClick(poData){
            this.ModalTitle = 'Edit Status Order: ';
            this.RequestOrderId = poData.purchase_order_id;
            this.RequestOrderNo = poData.purchase_order_number;
            
            this.StatusData = await globalfunc.globalDropdown('ddl_status_purchasing_purchase_order');
            this.Status = poData.status;
            
            window.$('#RequestOrderStatusModal').modal('show');
        },
        async saveClick(){
            var data = await requestOrderServices.getPOQuery(this.RequestOrderId);
            var itemGridRequestOrder = [];

            if(data.purchasing_purchase_order_detail.length != 0 ){
                for (let i = 0; i < data.purchasing_purchase_order_detail.length; i++) {
                    var str = 
                    { 
                        item_id: data.purchasing_purchase_order_detail[i].item_id,
                        uom: data.purchasing_purchase_order_detail[i].uom,
                        discount: data.purchasing_purchase_order_detail[i].discount,
                        quantity: data.purchasing_purchase_order_detail[i].quantity,
                        price_per_unit: data.purchasing_purchase_order_detail[i].price_per_unit,
                        price: data.purchasing_purchase_order_detail[i].price,
                        vat_per_unit: data.purchasing_purchase_order_detail[i].vat_per_unit,
                        vat: data.purchasing_purchase_order_detail[i].vat,
                    } 
                    itemGridRequestOrder.push(str);
                }
            }

            const poData = {
                purchase_order_number: data.purchase_order_number,
                status: this.Status,
                request_id: data.request_id,
                terms_of_payment: data.terms_of_payment,
                purchase_order_date: data.purchase_order_date,
                ppn_tax_percentage: data.ppn_tax_percentage,
                supplier_id: data.supplier_id,
                currency_id: data.currency_id,
                exchange_rate: data.exchange_rate,
                notes: data.notes,
                printed_notes: data.printed_notes,
                address: data.address,
                attachment: null,
                down_payment: data.down_payment,
                purchasing_purchase_order_detail:itemGridRequestOrder
            }

            const variables = {
                id : parseInt(this.RequestOrderId),
                data : poData
            }
            
            this.$swal(sweet_alert.update_status).then((result) => {
                if (result.isConfirmed == true) {
                    requestOrderServices.editQuery(variables).then(res => {
                        this.reload();
                        window.$('#RequestOrderStatusModal').modal('hide');
                        this.$swal("Info", response.successUpdate, "success");                 
                    }).catch(error => {
                        var errorMessage = this.$globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
    }
}
</script>

<style scoped>
.modal-xl-custom {
    max-width: 1500px;
}
</style>