<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="dataSource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            v-on:detailinit="detailInit"
            :resizable="true"
            >
        </kendo-grid>

        <file-viewer ref="fileViewer"/>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import {globalfunc} from '../../../../shared/GlobalFunction';
import requestOrderServices from '../Script/RequestOrderServices';
import currencyService from '../../../MasterData/Currency/Script/CurrencyService.js';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import FileViewer from '../../../../shared/FileViewer.vue';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'RequestOrderGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'file-viewer': FileViewer,
    },
    props: ['editClick', 'deleteClick', 'statusClick', 'pdfClick'],
    data: function () {
        return  {
            dataSource:  new kendo.data.DataSource ({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            var swapField = [
                                {field: "supplier_name", new_field: "\"Supplier\".\"contact_name\""},
                                {field: "created_by", new_field: "\"Created\".\"myname\""},
                            ];
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter, swapField),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort, swapField)
                            }
                            const variables = {
                                paging : paging
                            }
                            return { query: requestOrderServices.readRequestOrderQuery(),
                                        variables : variables}
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetPurchasingPurchaseOrderV2.purchasing_purchase_order == null){
                            return [];
                        }else{
                            return response.data.GetPurchasingPurchaseOrderV2.purchasing_purchase_order;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetPurchasingPurchaseOrderV2.purchasing_purchase_order == null){
                            return 0;
                        }else{
                            return response.data.GetPurchasingPurchaseOrderV2.total;
                        }
                    },
                    model: {
                        fields: {
                            purchase_order_date: { type: "date" },
                            total_vat_purchase_order: {type: "number"},
                            created_at: { type: "date" },
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" },
            }),
            columns:  [
                { title: "Action", width: 250, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "status", title: "Status", width: 90, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} }, 
                { field: "purchase_order_number", title: "No. PO", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "purchase_order_date", title: "Tanggal PO", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}, 
                    template: "#= kendo.toString(kendo.parseDate(purchase_order_date, 'yyyy-MM-dd'), 'yyyy-MM-dd') #" },
                { field: "created_at", title: "Tanggal Dibuat", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}, 
                    template: "#= kendo.toString(kendo.parseDate(new Date(created_at).toISOString(), 'yyyy-MM-dd'), 'yyyy-MM-dd') #" },
                { field: "total_vat_purchase_order", title: "Total", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, format: "{0:N2}",
                    template: "#= kendo.toString(Math.round(total_vat_purchase_order), 'n') #" }, 
                { field: "request_number", title: "No. Request", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "supplier_name", title: "Vendor", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "notes", title: "Catatan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "attachment", title: "Attachment", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    template:this.columnFile },
                { field: "created_by", title: "Dibuat Oleh", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },    
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        };
    },
    mounted: async function() {
        var vueComponent = this;
        var grid = this.$refs.grid.kendoWidget();
        var deleteClick = this.$props.deleteClick;
        var editClick = this.$props.editClick;
        var statusClick = this.$props.statusClick;
        var pdfClick = this.$props.pdfClick;
       
        var GridElement = grid.element;

        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            deleteClick(dataItem.purchase_order_id);
        })

        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.purchase_order_id, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.purchase_order_id, true);
        })
        
        ////Status Form
        GridElement.on("click", "#StatusButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            const poData = {
                purchase_order_id : dataItem.purchase_order_id,
                purchase_order_number : dataItem.purchase_order_number,
                status : dataItem.status
            }
            
            statusClick(poData);
        })

        GridElement.on("click", "#PdfButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            pdfClick(dataItem.purchase_order_id, 'ID')
        })

        GridElement.on("click", "#PdfEnglishButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            pdfClick(dataItem.purchase_order_id, 'ENG')
        })

        //File Form
        GridElement.on("click", "#FileButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            vueComponent.$refs.fileViewer.viewClick(dataItem.attachment);
        })
      
    },
    methods: {
        columnButton(){
            var customButton = `&nbsp;&nbsp;
                                <button type="button" class="btn btn-secondary btn-sm rounded" id="PdfButton">
                                    <i class="fa fa-file-pdf-o"></i> </span>
                                </button>
                                &nbsp;&nbsp;
                                <button type="button" class="btn btn-warning btn-sm rounded" id="PdfEnglishButton">
                                    <i class="fa fa-file-pdf-o"></i> </span>
                                </button>`;
            return this.$globalfunc.gridActionWithStatusChangesButton("Order", customButton)
        },
        columnFile(e){
            return this.$globalfunc.gridFileViewButton(e.attachment);
        },
        detailInit: async function (e) {
            var data = await requestOrderServices.getPOQuery(e.data.purchase_order_id);
            var currData = await currencyService.getCurrencyById(data.currency_id);
            var currSymbol = currData.currency_symbol;
            
            $('<div/>').appendTo(e.detailCell).kendoGrid({
                ref: "detailGrid",
                dataSource: {
                    transport: {
                        read: function (x) {
                            x.success(data.purchasing_purchase_order_detail);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify(
                            {
                                query: options.query,
                                variables: options.variables
                            }
                        );
                    },
                    schema: {
                        data: function (response) {
                            if(response == null)
                                return []
                            else
                                return response
                        },
                        total: function(response) {
                            if(response == null)
                                return 0
                            else
                                return response.length;
                        }
                    },
                    pageSize: kendo_grid.default_detail_grid_pagesize,
                    aggregate: [{ field: "vat", aggregate: "sum" }]
                },
                scrollable: false,
                sortable: true,
                pageable: true,
                navigatable: true,
                resizable: true,
                columns: [
                    { field: 'product_code', title: "Kode Barang", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;"} },
                    { field: 'item_name', title: "Nama Barang", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: 'uom', title: "Satuan", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: 'request_quantity', title: "Request Quantity", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                    { field: 'ordered_quantity', title: "Ordered Quantity", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                    { field: 'remaining_quantity', title: "Remaining Quantity", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                    { field: 'quantity', title: "Quantity", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                    { field: 'vat', title: "Total", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" },
                        format: "{0:N2}", aggregates: ["sum"], footerTemplate: `<div style='float: right'> ` + currSymbol + ` #=  kendo.toString(Math.round(sum), 'n') #</div>`
                    },
                ],
            });
        },
        changeStatus(status){
            this.dataSource =  new kendo.data.DataSource ({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            var swapField = [
                                {field: "supplier_name", new_field: "\"Supplier\".\"contact_name\""},
                                {field: "created_by", new_field: "\"Created\".\"myname\""},
                            ];
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter, swapField),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort, swapField)
                            }
                            const variables = {
                                paging : paging,
                                status : status
                            }
                            return { query: requestOrderServices.readRequestOrderQuery(),
                                        variables : variables}
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetPurchasingPurchaseOrderV2.purchasing_purchase_order == null){
                            return [];
                        }else{
                            return response.data.GetPurchasingPurchaseOrderV2.purchasing_purchase_order;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetPurchasingPurchaseOrderV2.purchasing_purchase_order == null){
                            return 0;
                        }else{
                            return response.data.GetPurchasingPurchaseOrderV2.total;
                        }
                    },
                    model: {
                        fields: {
                            purchase_order_date: { type: "date" },
                            total_vat_purchase_order: {type: "number"},
                            created_at: { type: "date" },
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" },
            })
        },
    }
}
</script>